@import "~normalize.css/normalize.css";
@import "photoswipe/dist/photoswipe.css";
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import "photoswipe";
@import "colors";
@import "breakpoints.module";
@import "variables-default";
@import "variables-dark";
@import "globals";
@import "typography";
/*****************************************************
  3rd Party CSS
 *****************************************************/

.pswp__button--arrow--left,
.pswp__button--arrow--right {
	top: 50vh;
}
