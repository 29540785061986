:root {
	// Globals ========================================================================== */
	--global-font-size: 1rem;
	--global-font-weight: 400;
	--global-font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
		"Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--global-font-color: var(--smoke-inner);
	--global-background: var(--smoke-hex);
	--global-main-z-index: 1;
	// Paragraph ========================================================================== */
	--paragraph-lg-font-size: 1.25rem;

	// Media ========================================================================== */
	--media-border-radius: 0.75rem;

	// Input ========================================================================== */
	--input-color: var(--white-inner);
	--input-background: var(--white-hex);
	--input-border-color: var(--smoke-hex);
	--input-min-width: 8rem;
	--input-border-width: 1px;
	--input-radius: 0.5em;
	--input-height: 2.75em;
	--input-padding: 0 1em;

	// Containers ========================================================================== */
	--container-width: 70rem;
	--container-width-sm: 50rem;
	--container-width-lg: 90rem;
	--container-width-xl: 120rem;
	--container-spacing: 1rem;
	// Buttons ========================================================================== */
	--button-radius: 0.5em;
	--button-height: 2.75em;
	--button-padding: 0 1em;
	--button-border: 1px solid;
	--button-margin: 0 0.5rem 0.5rem 0;
	--button-border-color: var(--black-hex);
	--button-background: var(--black-hex);
	--button-background-hover: rgba(var(--black-rgb), 0.7);
	--button-color: var(--black-inner);

	--button-primary-border-color: var(--black-hex);
	--button-primary-background: var(--black-hex);
	--button-primary-background-hover: rgba(var(--black-rgb), 0.7);
	--button-primary-color: var(--black-inner);

	// Badges ========================================================================== */
	--badge-padding: 0 2em;
	--badge-height: 3em;
	--badge-margin: 0 0.5rem 0.5rem 0;
	--badge-background: var(--black-hex);
	--badge-color: var(--black-inner);
	--badge-radius: 4rem;
	--badge-font-size: 0.75rem;

	// Header ========================================================================== */
	--header-z-index: 1090;
	--header-height: 4rem;
	// Main ========================================================================== */
	--main-background: var(--black-hex);
	// UiFooter ========================================================================== */
	--footer-background: var(--black-hex);
	--footer-color: var(--black-inner);
	// Breadcrumbs ========================================================================== */
	--breadcrumbs-color: var(--black-hex);
	// RC Slider ========================================================================== */
	--slider-rail-background: var(--gray-hex);
	--slider-track-background: var(--smoke-hex);
	--slider-handle-background: var(--white-hex);
	--slider-handle-border-color: var(--black-hex);
	// Selection ========================================================================== */
	--selection-background: var(--bright-yellow-hex);
	--selection-color: var(--bright-yellow-inner);
	//Code
	--code-background: var(--black-hex);
	//Cards
	--card-background: var(--white-hex);
	--card-border-radius: var(--media-border-radius);
	--card-sub-headline-color: var(--bright-gray-hex);
	//Quote
	--quote-font-size: 1.5rem;
	//Note
	--note-border-radius: var(--media-border-radius);
	--note-background: var(--white-rgb);
	--note-font-color: var(--white-inner);
	--note-alert-background: var(--bright-red-rgb);
	--note-alert-font-color: var(--bright-red-inner);
	--note-warning-background: var(--bright-yellow-rgb);
	--note-warning-font-color: var(--bright-yellow-inner);
	//Hotspot
	--hotspot-dot-background: var(--bright-yellow-hex);
	--hotspot-dot-color: var(--bright-yellow-inner);
}
