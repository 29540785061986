:root {
	--black-hex: #000000;
	--black-rgb: 0, 0, 0;
	--black-inner: #ffffff;

	--dark-gray-hex: #121212;
	--dark-gray-rgb: 18, 18, 18;
	--dark-gray-inner: #d8d8d8;

	--gray-hex: #1e1e1e;
	--gray-rgb: 30, 30, 30;
	--gray-inner: #c7c7c7;

	--bright-gray-hex: #6e6e73;
	--bright-gray-rgb: 110, 110, 125;
	--bright-gray-inner: #c7c7c7;

	--white-hex: #ffffff;
	--white-rgb: 255, 255, 255;
	--white-inner: #000000;

	--smoke-hex: #f5f5f5;
	--smoke-rgb: 245, 245, 245;
	--smoke-inner: #000000;

	--spotify-hex: #1db954;
	--spotify-rgb: 29, 185, 84;
	--spotify-inner: #ffffff;

	--twitter-hex: #1da1f2;
	--twitter-rgb: 29, 161, 242;
	--twitter-inner: #ffffff;

	--dark-blue-hex: #1d1929;
	--dark-blue-rgb: 29, 25, 41;
	--dark-blue-inner: #ffffff;

	--bright-yellow-hex: #ffff00;
	--bright-yellow-rgb: 255, 255, 0;
	--bright-yellow-inner: #000000;

	--bright-red-hex: #ff5f56;
	--bright-red-rgb: 255, 95, 86;
	--bright-red-inner: #000000;

	--bright-green-hex: #27c93f;
	--bright-green-rgb: 39, 201, 63;
	--bright-green-inner: #ffffff;
}
